import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BatchRolesProdEnum } from '../../batch-framework/enum/batch-roles-prod.enum';
import { BatchRolesEnum } from '../../batch-framework/enum/batch-roles.enum';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { UserRoleService } from '../services/user-role/user-role.service';

@Directive({
  selector: '[showForRoles]',
})
export class ShowForRolesDirective implements OnDestroy {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserRoleService,
    private configManagerService: ConfigManagerService
  ) {
    this.isProd = configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }
  private inputRoles: (BatchRolesEnum | BatchRolesProdEnum)[] = [];
  private isProd: boolean;
  subscription: Subscription;

  @Input() set showForRoles(inputRoles: (BatchRolesEnum | BatchRolesProdEnum)[]) {
    const batchRolesProdEnum: (BatchRolesProdEnum | BatchRolesEnum)[] = Object.values(BatchRolesProdEnum);
    const batchRolesEnum: (BatchRolesProdEnum | BatchRolesEnum)[] = Object.values(BatchRolesEnum);
    this.inputRoles = inputRoles.filter((role) => {
      return this.isProd ? batchRolesProdEnum.includes(role) : batchRolesEnum.includes(role);
    });
    this.checkCurrentRole();
  }

  private checkCurrentRole(): void {
    this.subscription = this.userService.userSubject$.pipe(filter((user) => !!user)).subscribe((userLogged) => {
      if (this.inputRoles) {
        const hasAnyRol = this.inputRoles.map((rol) => this.userService.hasRole(rol)).includes(true);
        this.showElement(hasAnyRol);
      } else {
        this.showElement(false);
      }
    });
  }

  private showElement(exist: boolean): void {
    if (exist) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
