import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-application-env-dialog',
  templateUrl: './select-application-env-dialog.component.html',
  styleUrls: ['./select-application-env-dialog.component.scss'],
})
export class SelectApplicationEnvDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SelectApplicationEnvDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { lastRoute: string }
  ) {}

  ngOnInit(): void {}

  close(event: boolean): void {
    if (event) {
      this.dialogRef.close();
    }
  }
}
