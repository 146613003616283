<xpo-card class="clock-container">
  <xpo-card-content class="clock">
    <div class="clock-title">
      <mat-icon class="clock-title-icon">info</mat-icon>
      <span>Schedule Time and Start Date values must be entered in <b>Pacific Time</b> zone.</span>
    </div>
    <div class="clock-time">
      <span><b>Pacific Time:</b></span>
      <span style="margin-left: 5px;">{{ ptTime }}</span>
    </div>
  </xpo-card-content>
</xpo-card>
