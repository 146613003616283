import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoCheckboxModule,
  XpoChipsModule,
  XpoDialogModule,
  XpoSelectModule,
  XpoSnackBarModule,
  XpoStepperModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridModule } from 'ag-grid-angular';
import { OperatorsSummaryComponent } from '../batch-framework/dialogs/promotion-dialog/operators-summary/operators-summary.component';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { ApplicationSelectorComponent } from './components/application-selector/application-selector.component';
import { EnvironmentSelectorComponent } from './components/environment-selector/environment-selector.component';
import { HelperMenuComponent } from './components/helper-menu/helper-menu.component';
import { SelectorAppEnvComponent } from './components/selector-app-env/selector-app-env.component';
import { TimeInfoCardComponent } from './components/time-info-card/time-info-card.component';
import { ShowForRolesDirective } from './directives/show-for-roles';
@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    SelectorAppEnvComponent,
    ApplicationSelectorComponent,
    EnvironmentSelectorComponent,
    HelperMenuComponent,
    // Pipes/Directives
    ShowForRolesDirective,
    TimeInfoCardComponent,
  ],
  imports: [
    // Pipes/Directives
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    XpoCardModule,
    XpoSelectModule,
    XpoButtonModule,
    XpoDialogModule,
    MatCheckboxModule,
    XpoCheckboxModule,
    XpoSnackBarModule,
    XpoTabsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatInputModule,
    MatExpansionModule,
    MatTableModule,
    MatListModule,
    MatSlideToggleModule,
    XpoChipsModule,
    MatIconModule,
    DragDropModule,
    XpoStepperModule,
    MatMenuModule,
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    ApplicationSelectorComponent,
    EnvironmentSelectorComponent,
    SelectorAppEnvComponent,
    TimeInfoCardComponent,
    HelperMenuComponent,
    // Pipes/Directives
    ShowForRolesDirective,
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
