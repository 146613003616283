<ng-container *ngIf="isMultiple; else single">
  <ng-container *ngIf="link$">
    <mat-icon
      class="helper-icon"
      matTooltip="Documentation"
      matTooltipPosition="left"
      aria-tooltip="Icon helper for documentation about the application"
      [matMenuTriggerFor]="linkItemMenu"
      >help
    </mat-icon>
    <mat-menu #linkItemMenu="matMenu">
      <ng-container *ngFor="let link of link$">
        <a mat-menu-item href="{{ link.url }}" target="_blank">{{ link.label }}</a>
      </ng-container>
    </mat-menu>
  </ng-container>
</ng-container>
<ng-template #single>
  <ng-container *ngIf="link$">
    <mat-icon
      (click)="iconClick(link$[0].url)"
      class="helper-icon"
      [matTooltip]="link$[0].label"
      matTooltipPosition="left"
      aria-tooltip="Icon helper for documentation about the application"
      >help
    </mat-icon>
  </ng-container>
</ng-template>
