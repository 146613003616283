import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Application } from '@xpo-ltl/sdk-batchframework';

function instanceOfApplication(application: any): application is Application {
  return (
    !!application &&
    typeof application !== 'string' && // Not just string input in the autocomplete
    'applicationCd' in application
  ); // Has applicationCd property
}

export const ApplicationSelectionRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>
  !instanceOfApplication(control?.value) ? { matchRequired: true } : null;
