import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject } from 'rxjs';
import { BatchRolesProdEnum } from '../../../batch-framework/enum/batch-roles-prod.enum';
import { BatchRolesEnum } from '../../../batch-framework/enum/batch-roles.enum';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  private userSubject = new BehaviorSubject<User>(undefined);
  userSubject$ = this.userSubject.asObservable();
  user: User;
  private isProduction = false;

  constructor(private configManagerService: ConfigManagerService) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  setUser(user: User): void {
    if (user) {
      this.user = user;
      this.user.roles = user.roles.map((role) => role.substring(role.lastIndexOf('/') + 1));
      this.userSubject.next(this.user);
    }
  }

  hasRole(role: BatchRolesEnum | BatchRolesProdEnum): boolean {
    return this.user?.roles?.includes(role);
  }
}
