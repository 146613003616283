import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { Application } from '@xpo-ltl/sdk-batchframework';
import { EnvironmentCd, User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BatchFrameworkService } from './batch-framework/batch-framework.service';
import { SelectApplicationEnvDialogComponent } from './batch-framework/dialogs/select-application-env-dialog/select-application-env-dialog.component';
import { BatchRolesProdEnum } from './batch-framework/enum/batch-roles-prod.enum';
import { BatchRolesEnum } from './batch-framework/enum/batch-roles.enum';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  previousUrl: string;
  routeSubscription: Subscription;
  private routeWithSwitchAppEnv: string[] = ['/jobs', '/operators'];
  private isProd: boolean;

  constructor(
    private router: Router,
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    private dialog: MatDialog,
    private batchApplicationService: BatchFrameworkService
  ) {
    /** Shell setup */
    this.title = configManagerService.getSetting<string>(ConfigManagerProperties.appName);
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.isProd = configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);

    this.routeSubscription = router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });

    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {});
    this.getApplicationEnvInfo();
  }

  title: string;
  build: string;
  compactMode: boolean = true;
  currentApplication: Application | any;
  currentEnvironment: EnvironmentCd;
  application$: Observable<Application | any>;
  environment$: Observable<EnvironmentCd>;
  rolesCanManageApplications: (BatchRolesEnum | BatchRolesProdEnum)[] = [
    BatchRolesEnum.BF_PLT_ENG,
    BatchRolesProdEnum.BF_PLT_ENG,
  ];

  rolesCanManageConfigurationParameters: (BatchRolesEnum | BatchRolesProdEnum)[] = [
    BatchRolesProdEnum.BF_ADMIN,
    BatchRolesProdEnum.BF_PLT_ENG,
    BatchRolesEnum.BF_ADMIN,
    BatchRolesEnum.BF_PLT_ENG,
  ];

  rolesCanImpactAnalysis: (BatchRolesEnum | BatchRolesProdEnum)[] = [
    BatchRolesEnum.BF_PLT_ENG,
    BatchRolesProdEnum.BF_PLT_ENG,
  ];

  private readonly currentUserSubject = new BehaviorSubject<User>(undefined);
  readonly currentUser$ = this.currentUserSubject.asObservable();

  readonly AccountUrls = AccountUrls;

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  ngOnInit(): void {
    this.listenApplicationAndEnvironmentSelection();
  }

  private listenApplicationAndEnvironmentSelection(): void {
    this.batchApplicationService.applicationSubject$.subscribe((result: Application) => {
      if (result) {
        this.currentApplication = result;
      }
    });
    this.batchApplicationService.environmentSubject$.subscribe((result: EnvironmentCd) => {
      if (result) {
        this.currentEnvironment = result;
      }
    });
  }

  private signOut(): void {
    // Removing the local storage keys
    // this.loginService.clear();
  }

  openApplicationEnvModal() {
    const dialogRef = this.dialog.open(SelectApplicationEnvDialogComponent, { disableClose: true });
  }

  getApplicationEnvInfo(): void {
    this.application$ = this.batchApplicationService.applicationSubject$;
    this.environment$ = this.batchApplicationService.environmentSubject$;
  }

  switchConfig(): void {
    this.router.navigateByUrl(`${AppRoutes.SELECT_APPLICATION_ENV}${this.previousUrl}`);
  }

  showSectionByUrl(): boolean {
    return this.routeWithSwitchAppEnv.includes(this.router.url);
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
