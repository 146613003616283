import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  Application,
  BatchFrameworkApiService,
  ListBatchApplicationsQuery,
  ListBatchApplicationsResp,
} from '@xpo-ltl/sdk-batchframework';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/internal/operators/map';
import { startWith } from 'rxjs/internal/operators/startWith';

@Component({
  selector: 'application-selector',
  templateUrl: './application-selector.component.html',
  styleUrls: ['./application-selector.component.scss'],
})
export class ApplicationSelectorComponent implements OnInit {
  @Input()
  control: FormControl;

  @Input()
  applicationCd: string;

  @Input()
  disabled: boolean;

  @Input()
  enableError: boolean = true;

  @Output()
  applicationChanged = new EventEmitter<Application>();

  @Input()
  labelInside = false;

  @Input()
  width?: number;

  applications: Application[];
  filteredValueOptions$: Observable<Application[]>;

  constructor(private batchFrameworkApiService: BatchFrameworkApiService) {}

  ngOnInit(): void {
    if (!this.control) {
      this.control = new FormControl();
    }
    if (this.disabled) {
      this.control.disable();
    }
    this.getApplications();
  }

  optionChanged(event: MatAutocompleteSelectedEvent): void {
    this.applicationChanged.emit(event.option.value);
  }

  getApplications(): void {
    this.batchFrameworkApiService
      .listBatchApplications({ applicationName: '' } as ListBatchApplicationsQuery)
      .pipe(map((resp: ListBatchApplicationsResp) => resp.applications))
      .subscribe((apps: Application[]) => {
        this.applications = apps.sort((a, b) => a.applicationCd.localeCompare(b.applicationCd));
        this.setApplications();
        if (this.applicationCd) {
          this.control.setValue(apps.find((app) => app.applicationCd === this.applicationCd));
        }
      });
  }

  displayFn(application?: any): string | undefined {
    return application ? `${application.applicationCd} - ${application.name}` : undefined;
  }

  private setApplications(): void {
    this.filteredValueOptions$ = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      filter((value) => typeof value !== 'object'),
      map((value) => this.filterApps(value))
    );
  }

  private filterApps(applicationName: string): Application[] {
    const filterValue = applicationName.toLowerCase();
    return this.applications.filter((option: Application | any) =>
      `${option.applicationCd.toLowerCase()} - ${option.name.toLowerCase()}`.includes(filterValue)
    );
  }
}
