export enum BatchJobFormEnum {
  SCHEDULE = 'schedule',
  BATCH_JOB_ID = 'batchJobId',
  LIST_ACTION_CD = 'listActionCd',
  IS_SCHEDULE_MANUAL = 'isScheduleManual',
  START_DATE = 'startDateTime',
  BATCH_JOB_ARGUMENT = 'batchJobArgument',
  MANUAL = 'Manual',
  NEW = 'new',
  ENVIRONMENT = 'environment',
  APPLICATION = 'application',
  APPLICATION_CD = 'applicationCd',
  ENVIRONMENT_CD = 'environmentCd',
  NONE = 'None',
  STEPS = 'steps',
  JOB = 'job',
  ID = 'id',
  PUBLISH_DATE_TIME = 'publishDateTime',
}
