import { Component, OnInit } from '@angular/core';
import moment from 'moment-timezone';

@Component({
  selector: 'app-time-info-card',
  templateUrl: './time-info-card.component.html',
  styleUrls: ['./time-info-card.component.scss'],
})
export class TimeInfoCardComponent implements OnInit {
  ptTimer;
  ptTime: string;
  constructor() {}

  ngOnInit(): void {
    this.startPTclock();
  }

  private startPTclock(): void {
    this.ptTimer = setInterval(() => {
      this.ptTime = moment.tz(new Date(), 'America/Los_Angeles').format('hh:mm:ss A');
    }, 1000);
  }
}
