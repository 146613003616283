<xpo-shell class="app-Container" [drawerClosedByDefault]="true">
  <xpo-header-app-name>BatchMan</xpo-header-app-name>
  <xpo-header-sub-app-name
    ><span class="build-version">{{ build }}</span></xpo-header-sub-app-name
  >
  <xpo-header-navigation class="container-nav">
    <section>
      <button
        [routerLink]="['/applications']"
        routerLinkActive="xpo-selected"
        *showForRoles="rolesCanManageApplications"
      >
        Applications
      </button>
      <button [routerLink]="['/jobs']" routerLinkActive="xpo-selected">Jobs</button>
      <button [routerLink]="['/operators']" routerLinkActive="xpo-selected">Operators</button>
      <button
        [routerLink]="['/configuration-parameters']"
        routerLinkActive="xpo-selected"
        *showForRoles="rolesCanManageConfigurationParameters"
      >
        Configuration Parameters
      </button>
      <button
        [routerLink]="['/scheduled-executions']"
        routerLinkActive="xpo-selected"
        *showForRoles="rolesCanImpactAnalysis"
      >
        Impact Analysis
      </button>
    </section>
  </xpo-header-navigation>
  <xpo-header-utilities-actions>
    <app-helper-menu class="app-Container-help-icon"></app-helper-menu>
    <xpo-auth-user-profile>
      <xpo-auth-user-profile-links>
        <a [href]="AccountUrls.myAccount" target="_blank">My Account</a>
      </xpo-auth-user-profile-links>
    </xpo-auth-user-profile>
  </xpo-header-utilities-actions>
  <section *ngIf="currentApplication && currentEnvironment && showSectionByUrl()" class="app-env-info">
    <app-selector-app-env [compactMode]="compactMode"></app-selector-app-env>
  </section>
  <br *ngIf="!showSectionByUrl()" />
  <router-outlet></router-outlet>
</xpo-shell>
<app-notification></app-notification>
