import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { Application } from '@xpo-ltl/sdk-appmetadata';
import { Observable, Subscription } from 'rxjs';
import { BatchFrameworkService } from '../../../batch-framework/batch-framework.service';
import { BatchJobFormEnum } from '../../../batch-framework/enum/batch-job-form.enum';
import { BatchRolesProdEnum } from '../../../batch-framework/enum/batch-roles-prod.enum';
import { BatchRolesEnum } from '../../../batch-framework/enum/batch-roles.enum';
import { AppRoutes } from '../../enums/app-routes.enum';
import { ApplicationSelectionRequiredValidator } from '../../validators/batch-select-app.validator';

@Component({
  selector: 'app-selector-app-env',
  templateUrl: './selector-app-env.component.html',
  styleUrls: ['./selector-app-env.component.scss'],
})
export class SelectorAppEnvComponent implements OnInit, OnDestroy {
  @Input()
  compactMode: boolean = false;
  @Input()
  lastRoute: string;
  @Output()
  close = new EventEmitter<boolean>();
  filteredValueOptions$: Observable<Application[]>;
  settingForm: FormGroup;
  enableError: boolean = false;
  checked = false;
  subscription = new Subscription();

  constructor(private fb: FormBuilder, private batchService: BatchFrameworkService, private router: Router) {}

  rolesCanManageApplications: (BatchRolesEnum | BatchRolesProdEnum)[] = [
    BatchRolesEnum.BF_PLT_ENG,
    BatchRolesProdEnum.BF_PLT_ENG,
  ];

  rolesCanManageConfigurationParameters: (BatchRolesEnum | BatchRolesProdEnum)[] = [
    BatchRolesEnum.BF_PLT_ENG,
    BatchRolesProdEnum.BF_PLT_ENG,
  ];

  ngOnInit(): void {
    this.initForm();
    this.subscription.add(
      this.batchService.applicationSubject$.subscribe((app) => {
        this.settingForm.get(BatchJobFormEnum.APPLICATION).setValue(app);
      })
    );
    this.subscription.add(
      this.batchService.environmentSubject$.subscribe((env) =>
        this.settingForm.get(BatchJobFormEnum.ENVIRONMENT).setValue(env)
      )
    );
  }

  initForm() {
    this.settingForm = this.fb.group({
      application: ['', Validators.compose([ApplicationSelectionRequiredValidator, Validators.required])],
      environment: ['', Validators.required],
    });
  }

  allChecked(e: MatCheckboxChange): void {
    this.checked = e.checked;
    this.batchService.setFullApplicationAccess(e.checked);
    if (e.checked) {
      this.settingForm.disable();
    } else {
      this.settingForm.enable();
    }
  }

  save(): void {
    if (!this.checked) {
      this.batchService.setCurrentApplication(this.settingForm.get(BatchJobFormEnum.APPLICATION).value);
      this.batchService.setCurrentEnvironment(this.settingForm.get(BatchJobFormEnum.ENVIRONMENT).value);
    }
    if (!this.compactMode) {
      this.router.navigateByUrl(`/${this.lastRoute || AppRoutes.JOBS_PAGE}`);
    }
    this.closeDialog();
  }

  closeDialog(): void {
    this.close.emit(true);
  }

  goToApplicationPage(): void {
    this.router.navigateByUrl(`/${AppRoutes.APPLICATION_PAGE}`);
    this.closeDialog();
  }

  goToConfigurationParameterPage(): void {
    this.router.navigateByUrl(`/${AppRoutes.CONFIGURATION_PARAMETERS_PAGE}`);
    this.closeDialog();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
