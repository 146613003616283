import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EnvironmentCd } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'environment-selector',
  templateUrl: './environment-selector.component.html',
  styleUrls: ['./environment-selector.component.scss'],
})
export class EnvironmentSelectorComponent {
  @Input()
  control: FormControl;

  @Input()
  labelInside = false;

  environments = EnvironmentCd;

  constructor() {}
}
