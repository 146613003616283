<mat-label *ngIf="!labelInside" class="application-label">Application</mat-label>
<mat-form-field floatLabel="always" [style.width.px]="width">
  <mat-label *ngIf="labelInside" class="application-label">Application</mat-label>
  <input type="text" matInput [formControl]="control" [matAutocomplete]="autoValue" xpoAutocomplete />
  <mat-autocomplete #autoValue="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionChanged($event)">
    <mat-option *ngFor="let application of filteredValueOptions$ | async" [value]="application">
      {{ application.applicationCd }} - {{ application.name }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="enableError && control.errors?.matchRequired">
    Please select an application.
  </mat-error>
</mat-form-field>
