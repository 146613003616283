<ng-container *ngIf="!compactMode; else compactView">
  <xpo-card class="batch-application" *ngIf="!compactMode; else compactView">
    <xpo-card-header>
      <xpo-card-title class="batch-application-header">
        <p>Select Application</p>
      </xpo-card-title>
    </xpo-card-header>
    <xpo-card-content>
      <form class="batch-application-form" [formGroup]="settingForm">
        <application-selector [control]="settingForm.get('application')"></application-selector>
        <environment-selector [control]="settingForm.get('environment')"></environment-selector>
      </form>
      <div class="batch-application-dialog-buttons" mat-dialog-actions>
        <xpo-button-group fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-flat-button [disabled]="!settingForm.valid && !checked" (click)="save()" xpoSmallButton>
            Set Application
          </button>
        </xpo-button-group>
      </div>
    </xpo-card-content>
  </xpo-card>
  <xpo-card class="batch-application" *showForRoles="rolesCanManageApplications">
    <xpo-card-content>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="material-icons">expand_more</span>
            Admin Options
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="batch-application-dialog-buttons" mat-dialog-actions>
          <xpo-button-group>
            <button
              *showForRoles="rolesCanManageApplications"
              (click)="goToApplicationPage()"
              mat-flat-button
              xpoSmallButton
            >
              Manage Applications
            </button>
            <button
              *showForRoles="rolesCanManageConfigurationParameters"
              (click)="goToConfigurationParameterPage()"
              mat-flat-button
              xpoSmallButton
            >
              Manage Configuration Parameters
            </button>
          </xpo-button-group>
        </div>
      </mat-expansion-panel>
    </xpo-card-content>
  </xpo-card>
</ng-container>
<ng-template #compactView>
  <form class="batch-application-form-compact" [formGroup]="settingForm">
    <application-selector
      [enableError]="enableError"
      class="batch-application-form-compact-selector"
      [control]="settingForm.get('application')"
    ></application-selector>
    <environment-selector class="batch-application-form-compact-selector" [control]="settingForm.get('environment')">
    </environment-selector>
    <button mat-flat-button [disabled]="!settingForm.valid && !checked" (click)="save()" xpoSmallButton>
      Switch
    </button>
  </form>
</ng-template>
