export enum AppRoutes {
  LANDING_PAGE = 'landing-page',
  JOBS_PAGE = 'jobs',
  APPLICATION_PAGE = 'applications',
  DATA_ENTRY_PAGE = 'data-entry-page',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  SELECT_APPLICATION_ENV = 'select-application',
  CREATE = 'create',
  DETAIL = ':id',
  CONFIGURATION_PARAMETERS_PAGE = 'configuration-parameters',
  OPERATORS_PAGE = 'operators',
  LAST_ROUTE_PAGE = ':last-route',
  SCHEDULED_EXECUTIONS_PAGE = 'scheduled-executions',
  AUTH_PAGE = 'auth',
}
