import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Application } from '@xpo-ltl/sdk-batchframework';
import { EnvironmentCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BatchFrameworkConfig,
  Documentation,
  FirestoreAppConfig,
} from '../shared/components/helper-menu/model/firestore-app-config';
import { FirestoreEnum } from '../shared/enums/firestore-app.enum';
@Injectable({
  providedIn: 'root',
})
export class BatchFrameworkService {
  constructor(private fs: AngularFirestore) {}

  private fullApplicationAccess: boolean = false;

  private applicationSubject = new BehaviorSubject<Application | any>(undefined);
  private environmentSubject = new BehaviorSubject<EnvironmentCd | any>(undefined);
  private appConfigs = new BehaviorSubject<BatchFrameworkConfig | any>(undefined);

  jobHasBeenSaved$ = new BehaviorSubject<boolean>(undefined);
  applicationHasBeenSaved$ = new BehaviorSubject<boolean>(undefined);

  applicationSubject$ = this.applicationSubject.asObservable();
  environmentSubject$ = this.environmentSubject.asObservable();
  appConfigs$ = this.appConfigs.asObservable();

  setCurrentApplication(application: Application): void {
    if (application) {
      this.applicationSubject.next(application);
    }
  }

  setCurrentEnvironment(environment: EnvironmentCd): void {
    if (environment) {
      this.environmentSubject.next(environment);
    }
  }

  setFullApplicationAccess(hasAccess: boolean): void {
    this.fullApplicationAccess = hasAccess;
  }

  userHasFullApplicationAccess(): boolean {
    return this.fullApplicationAccess;
  }

  getSymAppConfigs(): Observable<BatchFrameworkConfig> {
    return this.fs
      .collection(FirestoreEnum.APP_KEY_CONFIGS)
      .doc<FirestoreAppConfig>(FirestoreEnum.BATCHMAN)
      .get()
      .pipe(
        map((doc) => {
          return doc.data().appConfigs;
        })
      );
  }

  setBatchFrameworkConfigs(batchFrameworkConfig: BatchFrameworkConfig): void {
    if (batchFrameworkConfig) {
      this.appConfigs.next(batchFrameworkConfig);
    }
  }
}
