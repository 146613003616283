import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BatchFrameworkService } from '../../../batch-framework/batch-framework.service';
import { Documentation } from './model/firestore-app-config';

@Component({
  selector: 'app-helper-menu',
  templateUrl: './helper-menu.component.html',
  styleUrls: ['./helper-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-helper-menu',
  },
})
export class HelperMenuComponent implements OnInit, AfterViewInit {
  link$: Array<Documentation>;

  constructor(private batchApplicationService: BatchFrameworkService) {}

  @Input()
  documentationLabel?: string = 'BatchMan - BCF';

  @Input()
  isMultiple?: boolean = true;

  ngOnInit(): void {
    this.batchApplicationService.getSymAppConfigs().subscribe((config) => {
      this.batchApplicationService.setBatchFrameworkConfigs(config);
      this.link$ = config.documentation.filter((doc) => doc.label === this.documentationLabel);
    });
  }

  ngAfterViewInit(): void {}

  iconClick(url: string) {
    window.open(url);
  }
}
