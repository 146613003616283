import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoCheckboxModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkBatchFrameworkModule } from '@xpo-ltl/sdk-batchframework';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { NotificationComponent } from './app-notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    BrowserAnimationsModule,
    ConfigManagerModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    XpoCheckboxModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    MatMenuModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    SdkBatchFrameworkModule,
    FormsModule,
    XpoLtlAuthUiModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    LocationApiService,
    NotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
